import React from 'react';

interface StarsTwinklingProps {
  startNumber?: number;
}

export const StarsTwinkling = ({ startNumber }: StarsTwinklingProps) => {
  return (
    <>
      {Array(startNumber)
        .fill(0)
        .map((item, index) => (
          <div key={index} className={`stars star-${index + 1}`}></div>
        ))}
    </>
  );
};
