import React from 'react';
import Link from 'next/link';

// Types
import { MenuDropdownPropsType } from 'src/types/Page';

// Constants
import { HEADER_STYLES } from 'public/constants/styles-constants';
import { SITE_DATA } from 'public/constants/common';

// Styles
import styles from './menuDropdown.module.css';

const MenuDropdown = ({
  navList = [],
  toggle = false,
  routerPath = '',
  className = 'bg-white',
  wrapperClassName = 'top-0 right-0',
  onClick = () => {}
}: MenuDropdownPropsType) => {
  const isPrideCard = SITE_DATA.prideCard;

  return (
    <>
      <div
        className={`${toggle ? '' : 'hidden'} w-screen h-screen relative`}
        onClick={onClick}
      />
      <div
        className={`${wrapperClassName} inline-block cursor-pointer absolute`}
        onClick={onClick}
      >
        <span className={`block ${styles['menu-icon']} ${className}`}></span>
        <span className={`block ${styles['menu-icon']} ${className}`}></span>
        <span className={`block ${styles['menu-icon']} ${className}`}></span>
      </div>
      {toggle && (
        <div
          className={`${wrapperClassName} flex flex-col px-6 py-4 bg-gray-dark w-36 absolute`}
        >
          {navList.map(item => {
            if (item.href !== '#') {
              return (
                <Link href={item.href} key={item.label}>
                  <a
                    className={`${
                      routerPath === item.href
                        ? `${
                            isPrideCard
                              ? 'text-secondary'
                              : HEADER_STYLES.navActiveText
                          }`
                        : HEADER_STYLES.navText
                    } text-lg text-white py-2 xs:text-xs font-primary font-normal`}
                  >
                    {item.label}
                  </a>
                </Link>
              );
            }

            return true;
          })}
        </div>
      )}
    </>
  );
};

export default MenuDropdown;
