import React from 'react';
import Head from 'next/head';

const MetaHead = () => {
  return (
    <Head>
      <meta
        name="viewport"
        content="viewport-fit=cover, width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
    </Head>
  );
};

export default MetaHead;
