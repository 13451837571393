import React, { Suspense } from 'react';
import { useRouter } from 'next/router';
import MetaHead from './MetaHead';
import Header from './Header';
import Footer from './Footer';

import { ROUTES } from 'src/constants/routes';

// Const
import { LEGAL_STYLES } from 'public/constants/styles-constants';
import { SITE_DATA } from 'public/constants/common';

interface LayoutPropsType {
  children?: React.ReactNode;
}

export const Layout = ({ children }: LayoutPropsType) => {
  const router = useRouter();
  const isCommunity = router.pathname.indexOf(ROUTES.COMMUNITY) > -1;
  const isAboutUs = router.pathname.indexOf(ROUTES.ABOUT_US) > -1;
  const isFAQ = router.pathname.indexOf(ROUTES.FAQ_PAGE) > -1;
  const isHome = router.pathname.indexOf(ROUTES.HOME) > -1;
  const isWhitePaper = router.pathname.indexOf(ROUTES.WHITE_PAPER) > -1;
  const isDogeCard = SITE_DATA.dogeCard;
  const isPrideCard = SITE_DATA.prideCard;

  return (
    <>
      <MetaHead />
      <Suspense fallback={<div className="min-h-screen bg-bg-main bg-main" />}>
        <div
          className={`${
            isHome && isDogeCard ? 'bg-black' : 'bg-bg-main'
          } min-h-screen ${
            isFAQ && isPrideCard ? 'bg-gradient' : 'bg-main'
          }  relative`}
        >
          {!(isCommunity || isAboutUs || isFAQ) && !isDogeCard && (
            <div
              className={
                `${
                  router.asPath.indexOf(ROUTES.LEGAL_PAGE) === -1
                    ? 'bg-cover'
                    : `${LEGAL_STYLES.contentLayout}`
                } bg-no-repeat bg-landing z-10 h-full w-full ${
                  isPrideCard ? 'z-10' : ''
                } ${isWhitePaper ? '' : 'absolute'}` /*${styles['bg-landing']}*/
              }
            />
          )}
          <div
            className={`${
              isDogeCard && (isCommunity || isAboutUs)
                ? 'bg-image-sub bg-no-repeat'
                : ''
            }`}
          >
            <Header />
            {children}
            <Footer />
          </div>
        </div>
      </Suspense>
    </>
  );
};
