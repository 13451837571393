import React from 'react';
import Link from 'next/link';

// Constants
import { ROUTES } from 'src/constants/routes';
import { NAV_FOOTER } from 'src/constants/common';
import LazyLoadImage from 'src/components/LazyLoadImage';
import {
  SITE_DATA,
  BUTTON_DOWNLOAD,
  INFO_TOLL,
  FOOTER_INFO,
  SIZE_IMAGE
} from 'public/constants/common';
import { FOOTER_STYLES } from 'public/constants/styles-constants';

// Components
import DownloadButtonsGroup from 'src/components/DownloadButtonsGroup';
import { SocialMedia } from 'src/components/SocialMediaList';

const Footer = () => {
  const isDogeCard = SITE_DATA.dogeCard;
  const isPrideCard = SITE_DATA.prideCard;
  const isAvalancheCard = SITE_DATA.avalancheCard;
  const isCauseCard = SITE_DATA.causeCard;
  const isPawsCard = SITE_DATA.pawsCard;

  return (
    <footer className={`w-full relative z-20 ${FOOTER_STYLES.bgWrapper}`}>
      <div className={`md:flex ${FOOTER_STYLES.contentLayout}`}>
        {/** LEFT SECTION */}
        <div
          className={`w-full xs:py-12 flex md:flex-row md:text-xs flex-col sm:text-left text-center ${FOOTER_STYLES.logoLayout}`}
        >
          <div className="flex flex-col sm:flex-row md:flex-col">
            <Link href={ROUTES.HOME}>
              <a
                className={`${
                  FOOTER_STYLES.logoContent
                } md:mb-5 md:mt-0.5 flex items-center sm:text-xl text-2md mb-2.5 justify-center md:justify-start
                ${isPawsCard ? 'flex flex-col' : ''}`}
              >
                {/* <LogoTitle /> */}
                {(isPrideCard || isCauseCard || isPawsCard) && (
                  <LazyLoadImage
                    alt="logo"
                    src={'/logos/white-logo.png'}
                    width={
                      isPawsCard
                        ? SIZE_IMAGE?.tertiary.width
                        : SIZE_IMAGE?.secondaryLogo?.width
                    }
                    height={
                      isPawsCard
                        ? SIZE_IMAGE?.tertiary.height
                        : SIZE_IMAGE?.secondaryLogo?.height
                    }
                  />
                )}
                {!isPawsCard && (
                  <span
                    className={[
                      `${FOOTER_STYLES.logoText} font-bold sm:text-3xl text-2md leading-none`
                    ].join('')}
                  >
                    {SITE_DATA.secondaryAppName || SITE_DATA.appName}
                  </span>
                )}
              </a>
            </Link>
            {/* md:w-full */}
            {isDogeCard && (
              <div className="text-lg items-center mt-11 sm:mt-0 md:mt-3 flex flex-col justify-center w-full ml-0 sm:flex-row sm:justify-end md:mt-0 md:ml-0 md:flex-col items-end md:items-start">
                {NAV_FOOTER.map(item => (
                  <Link href={item.href} key={item.label}>
                    <a
                      className={`text-base sm:text-lg text-footer pt-4 py-2 pl-0 leading-md sm:pl-6 sm:pt-0 md:pl-0 ${FOOTER_STYLES.navText}`}
                    >
                      {item.label}
                    </a>
                  </Link>
                ))}
                {isAvalancheCard && (
                  <ul className="mt-3 hidden md:block">
                    {INFO_TOLL?.map((item, index) => (
                      <li className="flex mb-3 items-center" key={item.key}>
                        <span
                          className={`bg-light-yellow mr-3 w-32 block contact-icon ${item.class}`}
                        />
                        <span className="text-left font-primary text-info-black text-sm block">
                          {item.info}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
        {/** RIGHT SECTION */}
        <div
          className={`md:container w-full flex flex-col md:items-start items-center justify-start ${FOOTER_STYLES.contentTextLayout}`}
        >
          {isDogeCard && (
            <>
              <p
                className={`py-2 md:text-left md:mx-0 text-center text-lg xs:text-base ${FOOTER_STYLES.contentText}`}
              >
                {SITE_DATA.appName} is not a bank. Evolve Bank and Trust, Member
                FDIC, provides banking services and issues the{' '}
                {SITE_DATA.appName} Debit Card.
              </p>
              <p
                className={`${FOOTER_STYLES.contentText} py-6 md:text-left md:mx-0 text-center sm:text-lg text-base`}
              >
                * You will earn {SITE_DATA.coinName} with each qualifying
                purchase on your {SITE_DATA.appName}. {SITE_DATA.coinName}{' '}
                “rewards” are credited to you in accordance with our{' '}
                <Link href={ROUTES.LEGAL_REWARDS_PAGE_LINK}>
                  <a className={`${FOOTER_STYLES.contentTextLink} ml-1`}>
                    Rewards Terms
                  </a>
                </Link>
                . Qualifying Purchases do not include any other payment or
                transfer, including incoming transactions, outgoing transactions
                where you are the receiving party, peer to peer transactions,
                ACH Transactions, ATM transactions, fees, chargebacks,
                adjustments, or wire transfers. {SITE_DATA.coinName} involves
                certain risks. For more information, see the{' '}
                <Link href={ROUTES.LEGAL_REWARDS_PAGE_LINK}>
                  <a className={`${FOOTER_STYLES.contentTextLink} ml-1`}>
                    Rewards Terms.
                  </a>
                </Link>{' '}
                Users will earn up to 8% back on qualifying purchases.
              </p>
              {isAvalancheCard && (
                <p
                  className={`${FOOTER_STYLES.contentText} pb-5 pt-2 md:text-left md:mx-0 text-center text-lg xs:text-base`}
                >
                  <sup className="text-tiny">2</sup> Early access to direct
                  deposit funds depends on the timing of the submission of the
                  payment file from the payer. We generally make these funds
                  available on the day the payment file is received, which may
                  be up to 2 days earlier than the scheduled payment date.
                </p>
              )}
            </>
          )}
          {!isDogeCard && (
            <>
              {/* <p className="py-2 text-note md:text-left md:mx-0 text-center text-lg xs:text-base mx-5 xs:mx-0 max-w-5xl xs:mx-2">
                {SITE_DATA.appName} is not a bank. Evolve Bank and Trust, Member FDIC, provides
                banking services and issues the {SITE_DATA.appName} Credit card.
              </p>
              <p className="py-6 text-note md:text-left md:mx-0 text-center text-lg xs:text-base">
                * You will earn Doge with each qualifying purchase on your {SITE_DATA.appName}.
                Crypto {`“rewards”`} are credited to you in accordance with our
                <Link href={ROUTES.LEGAL_REWARDS_PAGE_LINK}>
                  <a className="text-yellow text-highlight ml-1">Rewards Terms</a>
                </Link>
                . Qualifying Purchases do not include any other payment or transfer,
                including incoming transactions, outgoing transactions where you are
                the receiving party, peer to peer transactions, ACH Transactions,
                ATM transactions, fees, chargebacks, adjustments, or wire transfers.
                Dogecoin involves certain risks. For more information, see the
                <Link href={ROUTES.LEGAL_REWARDS_PAGE_LINK}>
                  <a className="text-yellow text-highlight ml-1">Rewards Terms</a>
                </Link>
                .
              </p>
              <p className="py-6 text-note md:text-left md:mx-0 text-center text-lg xs:text-base">
                * Early access to direct deposit funds depends on the timing of the
                submission of the payment file from the payer. We generally make
                these funds available on the day the payment file is received, which
                may be up to 2 days earlier than the scheduled payment date.
              </p> */}
              <p
                className={`${FOOTER_STYLES.contentText} py-2 md:text-left md:mx-0 text-center md:text-lg text-base`}
              >
                {SITE_DATA.secondaryAppName || SITE_DATA.appName} is a product
                of Liquidity Financial. Banking Services, including{' '}
                {SITE_DATA.secondaryAppName || SITE_DATA.appName}s and Demand
                Deposit Accounts, provided by Evolve Bank & Trust, Member FDIC.
                Banking platform services provided by Solid Financial
                Technologies, Inc.
              </p>
            </>
          )}
          {/* <p className="py-2 text-note md:text-left md:mx-0 text-center text-lg xs:text-base mx-5 xs:mx-0 max-w-5xl xs:mx-2">
            DogeCard is not a bank. Evolve Bank and Trust, Member FDIC, provides
            banking services and issues the DogeCard Secured Credit card.
          </p>
          <p className="py-6 text-note md:text-left md:mx-0 text-center text-lg xs:text-base">
            * You will earn Doge with each qualifying purchase on your DogeCard.
            Doge “rewards” are credited to you in accordance with our
            <Link href={ROUTES.LEGAL_REWARDS_PAGE_LINK}>
              <a className="text-yellow ml-1">Rewards Terms</a>
            </Link>
            . Qualifying Purchases do not include any other payment or transfer,
            including incoming transactions, outgoing transactions where you are
            the receiving party, peer to peer transactions, ACH Transactions,
            ATM transactions, fees, chargebacks, adjustments, or wire transfers.
            Dogecoin involves certain risks. For more information, see the
            <Link href={ROUTES.LEGAL_REWARDS_PAGE_LINK}>
              <a className="text-yellow ml-1">Rewards Terms</a>
            </Link>
            .
          </p>
          <p className="py-6 text-note md:text-left md:mx-0 text-center text-lg xs:text-base">
            * Early access to direct deposit funds depends on the timing of the
            submission of the payment file from the payer. We generally make
            these funds available on the day the payment file is received, which
            may be up to 2 days earlier than the scheduled payment date.
          </p>
          <p className="py-6 text-note md:text-left md:mx-0 text-center text-lg xs:text-base">
            * DogeCard offers promotional rate of up to 2% back in Dogecoin, for
            a 2 month period after signup. After the promotional period, the
            rate becomes variable.
          </p> */}
        </div>
      </div>
      {/* {isAvalancheCard && (
        <ul className="mt-8 md:hidden block">
          {INFO_TOLL?.map((item, index) => (
            <li
              className="flex mb-3 items-center justify-center"
              key={index.toString()}
            >
              <span
                className={`bg-light-yellow mr-3 w-32 block contact-icon ${item.class}`}
              />
              <span className="text-left font-primary text-info-black text-sm block">
                {item.info}
              </span>
            </li>
          ))}
        </ul>
      )} */}
      {isDogeCard && (
        <DownloadButtonsGroup
          btnInfosDefault={BUTTON_DOWNLOAD}
          className={`mx-auto sm:justify-center ${
            isAvalancheCard ? 'px-4' : ''
          }`}
          btnClass={`bg-black sm:mx-2 ${
            isAvalancheCard ? 'my-12 lg:my-20 lg:mt-10' : 'mt-8'
          }`}
        />
      )}
      <div className={`flex justify-between ${FOOTER_STYLES.copyRightsLayout}`}>
        <p
          className={`text-footer text-center capitalize ${FOOTER_STYLES.copyRights}`}
        >
          &copy; {FOOTER_INFO.copyRights}{' '}
          {SITE_DATA.secondaryAppName || SITE_DATA.appName}. All rights reserved
        </p>
        {isDogeCard && (
          <div className="flex justify-between mt-4 lg:mt-2 w-32">
            <SocialMedia />
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
