import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

// Constants
import { ROUTES } from '../constants/routes';
import { LINKS_TO } from 'src/constants/common';
import { ICONS_HEADER } from '../constants/images';
import { SITE_DATA, SIZE_IMAGE, NAV_HEADER } from 'public/constants/common';
import { HEADER_STYLES } from 'public/constants/styles-constants';

import useAuth from 'src/hocs/useAuth';

// Components
import { Button } from 'src/components/Button';
import LazyLoadImage from 'src/components/LazyLoadImage';
import { StarsTwinkling } from 'src/components/StarsTwinkling';
import MenuDropdown from 'src/components/MenuDropdown';

// Utils
import {
  checkShowEvolvedUrl,
  checkCommunityOrAboutUsUrl
} from 'src/utils/helper';

import styles from './Layout.module.css';

const Header = () => {
  const { logout } = useAuth();
  const router = useRouter();
  const [toggleMenu, setToggleMenu] = useState(false);

  const appName = SITE_DATA.appName;
  const isReferral = router.pathname.indexOf(ROUTES.REFERRAL_PAGE) > -1;
  const isLegal = router.pathname.indexOf(ROUTES.LEGAL_PAGE) > -1;
  const isFaq = router.pathname.indexOf(ROUTES.FAQ_PAGE) > -1;
  const isWhitePaper = router.pathname.indexOf(ROUTES.WHITE_PAPER) > -1;
  const isDogeCard = SITE_DATA.dogeCard;
  const isAvalancheCard = SITE_DATA.avalancheCard;
  const isDogeCardSkeleton = SITE_DATA.dogeCardSkeleton;
  const isAvalancheCardFaq = isAvalancheCard && (isFaq || isWhitePaper);

  const isTerraCard = SITE_DATA.terraCard;
  const isPrideCard = SITE_DATA.prideCard;
  const isShowEvolved = checkShowEvolvedUrl(router.pathname);
  const isCommunityOrAboutUs = checkCommunityOrAboutUsUrl(router.pathname);
  const isShowDropdownMenu =
    !(isReferral || isLegal) && (isDogeCard || isPrideCard || isAvalancheCard);

  const classTextCommunityAboutUs = isCommunityOrAboutUs
    ? 'text-info-black'
    : 'text-white';
  const classTextIconMember = !isDogeCard
    ? 'text-info-black mt-1'
    : classTextCommunityAboutUs;
  const classReferralFlex = isReferral ? 'justify-end py-4' : 'justify-between';

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <header
      className={`sm:py-4 w-full sm:px-10 px-4 ${
        isPrideCard ? 'xl:px-24 xl:py-8' : ''
      } ${
        !isReferral && !isLegal
          ? `pb-0 absolute top-0 py-4 bg-transparent ${HEADER_STYLES.contentLayout}`
          : ''
      }`}
    >
      {isReferral && isDogeCard && <StarsTwinkling startNumber={10} />}
      <div
        className={`relative md:h-24 w-full mx-auto px-0 flex items-center ${
          isLegal ? 'justify-center' : classReferralFlex
        }`}
      >
        {!isReferral && (
          <div
            className={`flex sm:justify-start ${
              !toggleMenu ? 'items-center' : ''
            } sm:min-h-24 xl:min-h-44 ${
              isPrideCard ? 'md:mt-10 w-auto' : 'w-64 sm:w-auto'
            }  ${isLegal ? 'justify-center py-5 md:py-0' : 'justify-between'}`}
          >
            <div className={`flex relative ${HEADER_STYLES.logoWrapper}`}>
              <Link href={ROUTES.HOME}>
                <a
                  className={`z-10 ${
                    !isReferral && !isLegal ? '' : 'text-info'
                  } ${
                    isDogeCardSkeleton
                      ? 'text-xs xl:text-2xl hidden font-primary font-normal sm:w-16 xl:w-32 sm:text-sm text-white'
                      : 'text-xs text-info-black'
                  } flex items-center w-14 h-22 sm:text-base sm:w-auto sm:h-auto justify-left flex-col`}
                >
                  <LazyLoadImage
                    alt="logo"
                    src={'/logos/logo-dialog.png'}
                    width={SIZE_IMAGE?.tertiary?.width}
                    height={SIZE_IMAGE?.tertiary?.height}
                  />
                  {(isDogeCard || isTerraCard) && (
                    <h1 className={HEADER_STYLES.logoText}>{appName}</h1>
                  )}
                </a>
              </Link>
            </div>
            {!isLegal && (
              <div
                className={`hidden sm:block flex items-center justify-start ${
                  isPrideCard ? 'sm:ml-8 lg:ml-20' : ''
                }`}
              >
                {NAV_HEADER.map(item => {
                  if (item.href !== '#') {
                    return (
                      <Link href={item.href} key={item.label}>
                        <a
                          className={`${
                            router.asPath === item.href
                              ? `${
                                  isAvalancheCardFaq
                                    ? 'text-eerie-black font-bold nav-active relative'
                                    : HEADER_STYLES.navActiveText
                                }`
                              : `${
                                  isAvalancheCardFaq
                                    ? 'text-eerie-black'
                                    : HEADER_STYLES.navText
                                }`
                          } text-lg py-2 mr-10 xs:text-xs xl:mr-20 font-primary font-normal`}
                        >
                          {item.label}
                        </a>
                      </Link>
                    );
                  }

                  return true;
                })}
              </div>
            )}
            {/* Menu dropdown on mobile */}
            {isShowDropdownMenu && (
              <div className="sm:hidden absolute top-0 right-0 top-2">
                <MenuDropdown
                  navList={NAV_HEADER}
                  toggle={toggleMenu}
                  routerPath={router.asPath}
                  className={`${HEADER_STYLES.colorDropDownMenu}`}
                  onClick={handleToggleMenu}
                />
              </div>
            )}
          </div>
        )}
        {isDogeCard && isShowEvolved && (
          <Link href={LINKS_TO.getEvolved}>
            <a
              target="_blank"
              className="flex sm:w-24 sm:h-auto w-10 h-10 items-center justify-start text-white flex flex-col text-xs text-center hidden sm:block"
            >
              <LazyLoadImage
                alt={'Member logo'}
                src={
                  (isCommunityOrAboutUs && isDogeCard) || isAvalancheCardFaq
                    ? ICONS_HEADER.frameMemberBlack
                    : ICONS_HEADER.fdicMember
                }
                width={SIZE_IMAGE?.fdicMember?.width}
                height={SIZE_IMAGE?.fdicMember?.height}
              />
              {isDogeCard && (
                <span
                  className={`leading-none hidden sm:block font-primary font-normal ${classTextIconMember}`}
                >
                  Evolve Bank & Trust
                </span>
              )}
            </a>
          </Link>
        )}
        {isReferral && (
          <Button
            label=""
            size=""
            onClick={logout}
            className={`text-highlight w-14 sm:w-40 flex items-center outline-none justify-center bg-bg-modal btn-logout ${styles['btn-logout']}`}
          >
            <span className="hidden sm:block pl-3">Logout</span>
          </Button>
        )}
      </div>
      {/* {!isReferral && !isLegal && isDogeCard && (
        <div className="sm:hidden text-center block flex items-center justify-center pt-10">
          {NAV_HEADER.map(item => (
            <Link href={item.href} key={item.label}>
              <a
                className={`${
                  router.asPath === item.href
                    ? HEADER_STYLES.navActiveText
                    : HEADER_STYLES.navText
                } text-base text-white sm:px-6 px-3`}
              >
                {item.label}
              </a>
            </Link>
          ))}
        </div>
      )} */}
    </header>
  );
};

export default Header;
