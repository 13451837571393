import React from 'react';

//Constants
import { SOCIAL_LINKS_SHARE } from 'public/constants/common';

import styles from './socialMediaList.module.css';

export const SocialMedia = () => {
  return (
    <>
      {SOCIAL_LINKS_SHARE.map(item => (
        <a href={item.href} target="_blank">
          <i
            className={`inline-block ${styles['social-icon']} ${
              styles[`${item.class}`]
            }`}
          />
        </a>
      ))}
    </>
  );
};
