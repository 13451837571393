import React, { ReactNode } from 'react';
import AnimationSpin from '../AnimationSpin';
import styles from './button.module.css';
import { BUTTONS_STYLES } from 'public/constants/styles-constants';

interface Button {
  children?: ReactNode;
  primary?: boolean;
  disabled?: boolean;
  label: string;
  href?: string;
  labelContent?: string;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  bgClassName?: string;
  size?: 'default' | 'large' | 'fullWidth' | 'medium' | '' | string;
  onClick?: (e: any) => void;
}

export const Button = ({
  primary = false,
  size = 'default',
  children,
  label,
  href,
  labelContent,
  onClick,
  type = 'button',
  className = '',
  bgClassName = '',
  disabled = false,
  ...props
}: Button) => (
  <button
    type={type}
    onClick={onClick}
    disabled={disabled}
    className={[
      styles['main-button'],
      styles[`storybook-button--${size}`],
      disabled ? styles['main-button-disabled'] : '',
      `flex justify-center items-center rounded-lg text-lg h-14 xs:mb-2 ${bgClassName ? bgClassName : BUTTONS_STYLES.bgColor} ${BUTTONS_STYLES.textColor} ${className}`
    ].join(' ')}
    {...props}
  >
    {disabled && <AnimationSpin />}
    {disabled ? '' : `${label}`}
    {children}
  </button>
);
