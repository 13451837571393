import React, { ReactNode } from 'react';
import styles from './downloadButton.module.css';

interface DownLoadButton {
  children?: ReactNode;
  labelContent?: string;
  key?: string;
  className?: string;
  href?: string;
}

const DownLoadButton = ({
  children,
  labelContent,
  className = '',
  key = '',
  href = '',
  ...props
}: DownLoadButton) => {
  return (
    <a
      className={[
        styles['btn-download'],
        `flex justify-center items-center rounded-lg text-lg h-14 bg-primary text-color-button ${className}`
      ].join(' ')}
      href={href}
      role="button"
      {...props}
    >
      {children}
      {labelContent && (
        <p dangerouslySetInnerHTML={{ __html: labelContent }}></p>
      )}
    </a>
  );
};

export default DownLoadButton;
