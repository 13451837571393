import React, { useEffect, useState } from 'react';

// Types
import { DownloadButtonsGroupPropsType } from '../../types/Page';

// Components
import DownloadButton from '../DownloadButton';

// Utils
import { getDownloadBtnInfos } from '../../utils/helper';

// Styles
import styles from './downloadButtonsGroup.module.css';

const DownloadButtonsGroup = ({
  className,
  btnClass,
  btnInfosDefault,
  ...props
}: DownloadButtonsGroupPropsType) => {
  const [buttonDownloadInfos, setButtonDownloadInfos] = useState(
    btnInfosDefault
  );

  useEffect(() => {
    const newButtonDownloadInfos = getDownloadBtnInfos(btnInfosDefault);
    setButtonDownloadInfos(newButtonDownloadInfos);
  }, []);

  return (
    <div
      className={`flex xs:items-center sm:max-w-md xs:mx-auto w-full md:px-0 mt-4 lg:mt-0 max-w-sm xs:justify-between w-full ${className}`}
      {...props}
    >
      {buttonDownloadInfos?.map((button, index) => (
        <DownloadButton
          labelContent={button.labelContent}
          key={button.key}
          href={button.href}
          className={`mt-3 border border-matte-gray w-42 justify-between lg:w-42 px-5 py-3 xs:px-3 h-12 xs:mr-0 rounded-lg ${styles['btn-download']} ${btnClass}`}
        >
          <i
            className={`block ${styles['button-icon']} ${
              styles[`${button.class}`]
            }`}
          />
        </DownloadButton>
      ))}
    </div>
  );
};

export default DownloadButtonsGroup;
